<template>
  <div class="edit">
    <div class="side-menu">
      <button class="side-menu-botton">
        <div class="icon back"></div>
        <span>もどる</span>
      </button>
      <button class="side-menu-botton">
        <div class="icon template"></div>
        <span>テンプレート</span>
      </button>
      <button class="side-menu-botton">
        <div class="icon photo"></div>
        <span>写真</span>
      </button>
      <button class="side-menu-botton">
        <div class="icon sticker"></div>
        <span>ステッカー</span>
      </button>
      <button class="side-menu-botton">
        <div class="icon text"></div>
        <span>テキスト</span>
      </button>
      <button class="side-menu-botton">
        <div class="icon face"></div>
        <span>顔カウント</span>
      </button>
      <div class="spacer"></div>
      <div class="zoom-in-out">
        <button class="in">
          <div class="icon in"></div>
          <span>拡大</span>
        </button>
        <div>
          <div class="icon percent"></div>
          <span>100%</span>
        </div>
        <button class="out">
          <div class="icon out"></div>
          <span>縮小</span>
        </button>
      </div>
    </div>
    <div class="edit-area">
      <div class="inner">
        <button class="prev">
          <div class="icon prev"></div>
        </button>
        <div class="center">
          <div class="paper">
            <canvas></canvas>
          </div>
          <div class="discription">
            <span class="page">3-4ページ</span
            ><span class="saved_at">更新：2024/07/03 14:49</span>
          </div>
        </div>
        <button class="next">
          <div class="icon back"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style lang="less">
.edit {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid;
  grid-template-columns: auto 1fr;
}

.side-menu {
  width: 55px;
  height: 100%;
  background-color: white;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;

  .side-menu-botton {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    border-bottom: 1px solid #afafaf;

    .icon {
      width: 24px;
      height: 24px;
      background-color: #afafaf;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      transition: background-color 0.6s;

      &.back {
        mask-image: url("@/assets/icon_arrow_tab.svg");
      }

      &.template {
        mask-image: url("@/assets/icon_template_2.svg");
      }

      &.photo {
        mask-image: url("@/assets/icon_library_tab.svg");
      }

      &.sticker {
        mask-image: url("@/assets/icon_sticker.svg");
      }

      &.text {
        mask-image: url("@/assets/icon_text_2.svg");
      }

      &.face {
        mask-image: url("@/assets/icon-meibo.svg");
      }
    }

    span {
      font-size: 8px;
      color: #afafaf;
      font-weight: 700;
    }

    &:hover {
      .icon {
        background-color: #61a7cb;
      }
      span {
        color: #61a7cb;
      }
    }
  }
  .spacer {
    flex: 1;
  }

  .zoom-in-out {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    gap: 5px;

    div,
    button {
      width: 100%;
      aspect-ratio: ~"4/3";
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .icon {
        width: 24px;
        height: 24px;
        background-color: #afafaf;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        transition: background-color 0.6s;

        &.in {
          mask-image: url("@/assets/canvas-expansion.svg");
        }

        &.out {
          mask-image: url("@/assets/canvas-shrink.svg");
        }

        &.percent {
          mask-image: url("@/assets/show-whole.svg");
        }
      }

      span {
        font-size: 8px;
        color: #afafaf;
        font-weight: 700;
      }
    }

    button {
      &:hover {
        .icon {
          background-color: #61a7cb;
        }
        span {
          color: #61a7cb;
        }
      }
    }
  }
}

.edit-area {
  width: 100%;
  height: 100%;
  background-color: #afafaf;
  display: flex;
  overflow: auto;
  padding: 20px;
  .inner {
    margin: auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 20px;
    .prev,
    .next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 60px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
      .icon {
        width: 24px;
        height: 24px;
        background-color: #524b3d;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        transition: background-color 0.6s;

        &.back {
          mask-image: url("@/assets/next_524b3d.svg");
        }

        &.prev {
          mask-image: url("@/assets/back_524b3d.svg");
        }
      }

      &:hover {
        .icon {
          background-color: #61a7cb;
        }
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .paper {
        canvas {
          width: 1142px;
          height: 822px;
          background-color: #fff;
        }
      }
      .discription {
        font-size: 12px;
        color: #fff;
        font-weight: 700;
        display: flex;
        gap: 10px;
      }
    }
  }
}
</style>
